import React from 'react';
import * as styles from './GeneratorBox.module.scss';

interface Props {
    children: React.ReactChild,
    largeFont?: boolean,
}

const GeneratorBox = ({ children, largeFont = false }: Props) => {
    return (
            <div className={`${styles.generatorBox} ${largeFont ? styles.large : ''}`}>
                {children}
            </div>
    )
}

export default GeneratorBox;
